export default function (extension: string): string {
	switch (extension) {
		case "pdf":
			return "pi-file-pdf";

		case "doc":
		case "docx":
			return "pi-file-word";

		case "xls":
		case "xlsx":
			return "pi-file-excel";

		case "jpg":
		case "jpeg":
		case "png":
		case "webp":
			return "pi-image";

		default:
			return "pi-file";
	}
}
